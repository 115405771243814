import { WeatherStationData } from '@/store/weatherstation/definitions'

export const defaultBeginningSets = {
  datasetTemperature: (trackOrWbgt: 'track' | 'wbgt') => ([{
    name: 'Air (°C)',
    type: 'line',
    x: [],
    y: [],
    min: null,
    max: null,
    avg: null,
    sum: 0,
    itemsLength: 0, // number of temperatures set
    marker: {
      color: '#b9a26c'
    }
  }, {
    name: 'Track (°C)',
    type: 'line',
    x: [],
    y: [],
    min: null,
    max: null,
    avg: null,
    sum: 0,
    itemsLength: 0, // number of temperatures set
    marker: {
      color: '#03bde4'
    }
  }, {
    name: 'WBGT (°C)',
    type: 'line',
    x: [],
    y: [],
    yaxis: 'y',
    min: null,
    max: null,
    avg: null,
    sum: 0,
    itemsLength: 0, // number of temperatures set
    marker: {
      color: trackOrWbgt === 'track' ? '#80ff7e' : '#03bde4'
    }
  }, {
    name: 'Heat Index (°C)',
    type: 'line',
    x: [],
    y: [],
    yaxis: 'y',
    min: null,
    max: null,
    avg: null,
    sum: 0,
    itemsLength: 0, // number of temperatures set
    marker: {
      color: '#DD66D8'
    }
  }, {
    isAnnotation: true,
    xref: 'paper',
    x: 1,
    y: 0,
    xanchor: 'left',
    yanchor: 'middle',
    text: 0,
    font: {
      color: 'var(color-font)'
    },
    showarrow: false
  }, {
    isAnnotation: true,
    xref: 'paper',
    x: 1,
    y: 0,
    xanchor: 'left',
    yanchor: 'middle',
    text: 0,
    font: {
      color: 'var(color-font)'
    },
    showarrow: false
  }, {
    isAnnotation: true,
    xref: 'paper',
    x: 1,
    y: 0,
    xanchor: 'left',
    yanchor: 'middle',
    text: 0,
    font: {
      color: 'var(color-font)'
    },
    showarrow: false
  }, {
    isAnnotation: true,
    xref: 'paper',
    x: 1,
    y: 0,
    xanchor: 'left',
    yanchor: 'middle',
    text: 0,
    font: {
      color: 'var(color-font)'
    },
    showarrow: false
  }]),
  datasetWind: () => ([{
    name: 'Average (kph)',
    type: 'line',
    x: [],
    y: [],
    min: null,
    max: null,
    avg: null,
    sum: 0,
    itemsLength: 0, // number of temperatures set
    marker: {
      color: '#b9a26c'
    }
  }, {
    name: 'Gusts (kph)',
    type: 'line',
    x: [],
    y: [],
    min: null,
    max: null,
    avg: null,
    sum: 0,
    itemsLength: 0, // number of temperatures set
    marker: {
      color: '#03bde4'
    }
  }, {
    isAnnotation: true,
    xref: 'paper',
    x: 1,
    y: 0,
    xanchor: 'left',
    yanchor: 'middle',
    text: 0,
    font: {
      color: 'var(color-font)'
    },
    showarrow: false
  }, {
    isAnnotation: true,
    xref: 'paper',
    x: 1,
    y: 0,
    xanchor: 'left',
    yanchor: 'middle',
    text: 0,
    font: {
      color: 'var(color-font)'
    },
    showarrow: false
  }]),
  datasetHuPressure: () => ([{
    name: 'Humidity (%)',
    type: 'line',
    x: [],
    y: [],
    yaxis: 'y',
    min: null,
    max: null,
    avg: null,
    sum: 0,
    itemsLength: 0, // number of temperatures set
    marker: {
      color: '#b9a26c'
    }
  }, {
    name: 'Pressure (hPa)',
    type: 'line',
    x: [],
    y: [],
    yaxis: 'y2',
    min: null,
    max: null,
    avg: null,
    sum: 0,
    itemsLength: 0, // number of temperatures set
    marker: {
      color: '#03bde4'
    }
  }]),
  datasetRain: () => ([{
    name: 'Rain (mm/h)',
    type: 'bar',
    x: [],
    y: [],
    yaxis: 'y',
    min: null,
    max: null,
    avg: null,
    sum: 0,
    itemsLength: 0, // number of temperatures set
    marker: {
      color: '#03bde4'
    }
  }]),
  datasetWindDirection: () => ({
    x: [],
    y: []
  }),
  datasetWindRoseDirection: () => ([{
    name: '< 5 km/h',
    type: 'barpolar',
    r: [],
    theta: [
      'E', 'ENE', 'NE', 'NNE',
      'N', 'NNW', 'NW', 'WNW',
      'W', 'WSW', 'SW', 'SSW',
      'S', 'SSE', 'SE', 'ESE'
    ],
    marker: {
      color: '#010183'
    },
    sum: 0,
    itemsLength: 0,
    avg: null
  }, {
    name: '5-10 km/h',
    type: 'barpolar',
    r: [],
    theta: [
      'E', 'ENE', 'NE', 'NNE',
      'N', 'NNW', 'NW', 'WNW',
      'W', 'WSW', 'SW', 'SSW',
      'S', 'SSE', 'SE', 'ESE'
    ],
    marker: {
      color: '#80ff7e'
    },
    sum: 0,
    itemsLength: 0,
    avg: null
  }, {
    name: '10-15 km/h',
    type: 'barpolar',
    r: [],
    theta: [
      'E', 'ENE', 'NE', 'NNE',
      'N', 'NNW', 'NW', 'WNW',
      'W', 'WSW', 'SW', 'SSW',
      'S', 'SSE', 'SE', 'ESE'
    ],
    marker: {
      color: '#ffd402'
    },
    sum: 0,
    itemsLength: 0,
    avg: null
  }, {
    name: '15-20 km/h',
    type: 'barpolar',
    r: [],
    theta: [
      'E', 'ENE', 'NE', 'NNE',
      'N', 'NNW', 'NW', 'WNW',
      'W', 'WSW', 'SW', 'SSW',
      'S', 'SSE', 'SE', 'ESE'
    ],
    marker: {
      color: '#ff2902'
    },
    sum: 0,
    itemsLength: 0,
    avg: null
  }, {
    name: '> 20 km/h',
    type: 'barpolar',
    r: [],
    theta: [
      'E', 'ENE', 'NE', 'NNE',
      'N', 'NNW', 'NW', 'WNW',
      'W', 'WSW', 'SW', 'SSW',
      'S', 'SSE', 'SE', 'ESE'
    ],
    marker: {
      color: '#830202'
    },
    sum: 0,
    itemsLength: 0,
    avg: null
  }]),
  datasetLightIntensity: () => ([{
    name: 'Light Intensity (Lx)',
    type: 'line',
    x: [],
    y: [],
    yaxis: 'y',
    min: null,
    max: null,
    avg: null,
    sum: 0,
    itemsLength: 0, // number of temperatures set
    marker: {
      color: '#b9a26c'
    }
  }, {
    isAnnotation: true,
    xref: 'paper',
    x: 1,
    y: 0,
    xanchor: 'left',
    yanchor: 'middle',
    text: 0,
    font: {
      color: 'var(color-font)',
      size: 11
    },
    showarrow: false
  }]),
  minTemperature: () => (null),
  maxTemperature: () => (null),
  minWind: () => (null),
  maxWind: () => (null),
  minHumidity: () => (null),
  maxHumidity: () => (null),
  minPressure: () => (null),
  maxPressure: () => (null),
  maxRain: () => (null),
  rainIntensityTotal: () => (0)
}

export function getDatasets (newData: WeatherStationData[], trackOrWbgt: 'track' | 'wbgt' = 'track') {
  if (!newData || newData.length === 0) return
  const result = {
    datasetTemperature: null,
    datasetWind: null,
    datasetHuPressure: null,
    datasetRain: null,
    datasetWindDirection: {
      x: [],
      y: []
    },
    datasetWindRoseDirection: null,
    datasetLightIntensity: null,
    minTemperature: null,
    maxTemperature: null,
    minWind: null,
    maxWind: null,
    minHumidity: null,
    maxHumidity: null,
    minPressure: null,
    maxPressure: null,
    maxRain: null,
    minWBGT: null,
    maxWBGT: null,
    minHeatIndex: null,
    maxHeatIndex: null,
    minLightIntensity: null,
    maxLightIntensity: null,
    rainIntensityTotal: 0,
    pressureAddition: 5,
    windAddition: 5,
    temperatureAddition: 3,
    humidityAddition: 1
  }
  Object.keys(defaultBeginningSets).forEach(key => {
    result[key] = defaultBeginningSets[key](trackOrWbgt)
  })
  const {
    datasetTemperature,
    datasetWind,
    datasetWindDirection,
    datasetWindRoseDirection,
    datasetHuPressure,
    datasetRain,
    datasetLightIntensity
  } = result
  const directionLabel = [
    'E', 'ENE', 'NE', 'NNE',
    'N', 'NNO', 'NO', 'ONO',
    'O', 'OSO', 'SO', 'SSO',
    'S', 'SSE', 'SE', 'ESE'
  ]
  directionLabel.forEach(currentDirection => {
    const directionsOccurences = [
      0, // > 20km/h
      0, // 15-20
      0, // 10-15
      0, // 5-10
      0 // <5
    ]
    newData.forEach(v => {
      if (v.windDirection &&
        v.windDirection.toUpperCase() === currentDirection) {
        if (v.windSpeed > 20) {
          directionsOccurences[4]++
        } else if (v.windSpeed > 15) {
          directionsOccurences[3]++
        } else if (v.windSpeed > 10) {
          directionsOccurences[2]++
        } else if (v.windSpeed > 5) {
          directionsOccurences[1]++
        } else {
          directionsOccurences[0]++
        }
      }
    })
    directionsOccurences.forEach((directionOccurence, index) => {
      datasetWindRoseDirection[index].r.push(Math.round(100 * directionOccurence / newData.length))
    })
  })

  newData.forEach(v => {
    datasetTemperature[0].x.push(v.timeFormatted)
    datasetTemperature[1].x.push(v.timeFormatted)
    datasetTemperature[2].x.push(v.timeFormatted)
    datasetTemperature[3].x.push(v.timeFormatted)
    datasetWind[0].x.push(v.timeFormatted)
    datasetWind[1].x.push(v.timeFormatted)
    datasetHuPressure[0].x.push(v.timeFormatted)
    datasetHuPressure[1].x.push(v.timeFormatted)
    datasetRain[0].x.push(v.timeFormatted)
    datasetWindDirection.x.push(v.timeFormatted)
    datasetLightIntensity[0].x.push(v.timeFormatted)

    if (v.airTemperature !== '--' && v.airTemperature >= 0) {
      (result.minTemperature === null || result.minTemperature > v.airTemperature) && (result.minTemperature = v.airTemperature);
      (result.maxTemperature === null || result.maxTemperature < v.airTemperature) && (result.maxTemperature = v.airTemperature);
      (datasetTemperature[0].min === null || datasetTemperature[0].min > v.airTemperature) && (datasetTemperature[0].min = v.airTemperature);
      (datasetTemperature[0].max === null || datasetTemperature[0].max < v.airTemperature) && (datasetTemperature[0].max = v.airTemperature)
      datasetTemperature[0].y.push(v.airTemperature)
      datasetTemperature[0].sum += v.airTemperature
      datasetTemperature[0].itemsLength++
    } else {
      datasetTemperature[0].y.push(null)
    }

    if (v.trackTemperature !== '--' && v.trackTemperature >= 0) {
      (result.minTemperature === null || result.minTemperature > v.trackTemperature) && (result.minTemperature = v.trackTemperature);
      (result.maxTemperature === null || result.maxTemperature < v.trackTemperature) && (result.maxTemperature = v.trackTemperature);
      (datasetTemperature[1].min === null || datasetTemperature[1].min > v.trackTemperature) && (datasetTemperature[1].min = v.trackTemperature);
      (datasetTemperature[1].max === null || datasetTemperature[1].max < v.trackTemperature) && (datasetTemperature[1].max = v.trackTemperature)
      datasetTemperature[1].y.push(v.trackTemperature)
      datasetTemperature[1].sum += v.trackTemperature
      datasetTemperature[1].itemsLength++
    } else {
      datasetTemperature[1].y.push(null)
    }

    if (v.wbgtTemperature !== '--' && v.wbgtTemperature >= 0) {
      (result.minTemperature === null || result.minTemperature > v.wbgtTemperature) && (result.minTemperature = v.wbgtTemperature);
      (result.maxTemperature === null || result.maxTemperature < v.wbgtTemperature) && (result.maxTemperature = v.wbgtTemperature);
      (datasetTemperature[2].min === null || datasetTemperature[2].min > v.wbgtTemperature) && (datasetTemperature[2].min = v.wbgtTemperature);
      (datasetTemperature[2].max === null || datasetTemperature[2].max < v.wbgtTemperature) && (datasetTemperature[2].max = v.wbgtTemperature)
      datasetTemperature[2].y.push(v.wbgtTemperature)
      datasetTemperature[2].sum += v.wbgtTemperature
      datasetTemperature[2].itemsLength++
    } else {
      datasetTemperature[2].y.push(null)
    }

    if (v.heatIndex !== '--' && v.heatIndex >= 0) {
      (result.minHeatIndex === null || result.minHeatIndex > v.heatIndex) && (result.minHeatIndex = v.heatIndex);
      (result.maxHeatIndex === null || result.maxHeatIndex < v.heatIndex) && (result.maxHeatIndex = v.heatIndex);
      (datasetTemperature[3].min === null || datasetTemperature[3].min > v.heatIndex) && (datasetTemperature[3].min = v.heatIndex);
      (datasetTemperature[3].max === null || datasetTemperature[3].max < v.heatIndex) && (datasetTemperature[3].max = v.heatIndex)
      datasetTemperature[3].y.push(v.heatIndex)
      datasetTemperature[3].sum += v.heatIndex
      datasetTemperature[3].itemsLength++
    } else {
      datasetTemperature[3].y.push(null)
    }

    if (v.windSpeed !== '--' && v.windSpeed >= 0) {
      (result.minWind === null || result.minWind > v.windSpeed) && (result.minWind = v.windSpeed);
      (result.maxWind === null || result.maxWind < v.windSpeed) && (result.maxWind = v.windSpeed);
      (datasetWind[0].min === null || datasetWind[0].min > v.windSpeed) && (datasetWind[0].min = v.windSpeed);
      (datasetWind[0].max === null || datasetWind[0].max < v.windSpeed) && (datasetWind[0].max = v.windSpeed)
      datasetWind[0].y.push(v.windSpeed)
      datasetWind[0].sum += v.windSpeed
      datasetWind[0].itemsLength++
    } else {
      datasetWind[0].y.push(null)
    }

    if (v.windDirection !== '--' && v.windDirection !== undefined) {
      datasetWindDirection.y.push({ direction: v.windDirection, time: v.timeFormatted })
      datasetWindRoseDirection[0].sum += (v.windDirectionDegree === 0 ? 360 : v.windDirectionDegree)
    } else {
      datasetWindDirection.y.push({ direction: 'unknown', time: v.timeFormatted })
    }
    datasetWindRoseDirection[0].itemsLength++

    if (v.gusts !== '--' && v.gusts >= 0) {
      (result.minWind === null || result.minWind > v.gusts) && (result.minWind = v.gusts);
      (result.maxWind === null || result.maxWind < v.gusts) && (result.maxWind = v.gusts);
      (datasetWind[1].min === null || datasetWind[1].min > v.gusts) && (datasetWind[1].min = v.gusts);
      (datasetWind[1].max === null || datasetWind[1].max < v.gusts) && (datasetWind[1].max = v.gusts)
      datasetWind[1].y.push(v.gusts)
      datasetWind[1].sum += v.gusts
      datasetWind[1].itemsLength++
    } else {
      datasetWind[1].y.push(null)
    }

    if (v.humidity !== '--' && v.humidity >= 0) {
      (result.minHumidity === null || result.minHumidity > v.humidity) && (result.minHumidity = v.humidity);
      (result.maxHumidity === null || result.maxHumidity < v.humidity) && (result.maxHumidity = v.humidity);
      (datasetHuPressure[0].min === null || datasetHuPressure[0].min > v.humidity) && (datasetHuPressure[0].min = v.humidity);
      (datasetHuPressure[0].max === null || datasetHuPressure[0].max < v.humidity) && (datasetHuPressure[0].max = v.humidity)
      datasetHuPressure[0].y.push(v.humidity)
      datasetHuPressure[0].sum += v.humidity
      datasetHuPressure[0].itemsLength++
    } else {
      datasetHuPressure[0].y.push(null)
    }

    if (v.pressure !== '--' && v.pressure >= 0) {
      (result.minPressure === null || result.minPressure > v.pressure) && (result.minPressure = v.pressure);
      (result.maxPressure === null || result.maxPressure < v.pressure) && (result.maxPressure = v.pressure);
      (datasetHuPressure[1].min === null || datasetHuPressure[1].min > v.pressure) && (datasetHuPressure[1].min = v.pressure);
      (datasetHuPressure[1].max === null || datasetHuPressure[1].max < v.pressure) && (datasetHuPressure[1].max = v.pressure)
      datasetHuPressure[1].y.push(v.pressure)
      datasetHuPressure[1].sum += v.pressure
      datasetHuPressure[1].itemsLength++
    } else {
      datasetHuPressure[1].y.push(null)
    }

    if (v.rainIntensity !== '--' && v.rainIntensity >= 0) {
      result.rainIntensityTotal += (v.rainIntensity / 60); // divide every value by 60 and sum them all
      (result.maxRain === null || result.maxRain < v.rainIntensity) && (result.maxRain = v.rainIntensity);
      (datasetRain[0].min === null || datasetRain[0].min > v.rainIntensity) && (datasetRain[0].min = v.rainIntensity);
      (datasetRain[0].max === null || datasetRain[0].max < v.rainIntensity) && (datasetRain[0].max = v.rainIntensity)
      datasetRain[0].y.push(v.rainIntensity)
      datasetRain[0].sum += v.rainIntensity
      datasetRain[0].itemsLength++
    } else {
      datasetRain[0].y.push(null)
    }

    if (v.lightIntensity !== '--' && v.lightIntensity >= 0) {
      (result.minLightIntensity === null || result.minLightIntensity > v.lightIntensity) && (result.minLightIntensity = v.lightIntensity);
      (result.maxLightIntensity === null || result.maxLightIntensity < v.lightIntensity) && (result.maxLightIntensity = v.lightIntensity);
      (datasetLightIntensity[0].min === null || datasetLightIntensity[0].min > v.lightIntensity) && (datasetLightIntensity[0].min = v.lightIntensity);
      (datasetLightIntensity[0].max === null || datasetLightIntensity[0].max < v.lightIntensity) && (datasetLightIntensity[0].max = v.lightIntensity)
      datasetLightIntensity[0].y.push(v.lightIntensity)
      datasetLightIntensity[0].sum += v.lightIntensity
      datasetLightIntensity[0].itemsLength++
    } else {
      datasetLightIntensity[0].y.push(null)
    }
  });

  (datasetTemperature[0].itemsLength > 0) &&
    (datasetTemperature[0].avg = (datasetTemperature[0].sum / datasetTemperature[0].itemsLength).toFixed(1))
  const lastAirTemperature = newData[newData.length - 1].airTemperature
  if (lastAirTemperature !== '--' && lastAirTemperature) {
    datasetTemperature[4].y = lastAirTemperature
    datasetTemperature[4].text = lastAirTemperature + '°'
  }

  (datasetTemperature[1].itemsLength > 0) &&
    (datasetTemperature[1].avg = (datasetTemperature[1].sum / datasetTemperature[1].itemsLength).toFixed(1))
  const lastTrackTemperature = newData[newData.length - 1][trackOrWbgt === 'track' ? 'trackTemperature' : 'wbgtTemperature']
  if (lastTrackTemperature !== '--' && lastTrackTemperature) {
    datasetTemperature[5].y = lastTrackTemperature
    datasetTemperature[5].text = lastTrackTemperature + '°'
  }

  (datasetTemperature[2].itemsLength > 0) &&
    (datasetTemperature[2].avg = (datasetTemperature[2].sum / datasetTemperature[2].itemsLength).toFixed(1))
  const lastWBGT = newData[newData.length - 1].wbgtTemperature
  if (lastWBGT !== '--' && lastWBGT) {
    datasetTemperature[6].y = lastWBGT
    datasetTemperature[6].text = lastWBGT + '°'
  }

  (datasetTemperature[3].itemsLength > 0) &&
    (datasetTemperature[3].avg = (datasetTemperature[3].sum / datasetTemperature[3].itemsLength).toFixed(1))
  const lastHeatIndex = newData[newData.length - 1].heatIndex
  if (lastHeatIndex !== '--' && lastHeatIndex) {
    datasetTemperature[7].y = lastHeatIndex
    datasetTemperature[7].text = lastHeatIndex + '°'
  }

  (datasetWind[0].itemsLength > 0) &&
    (datasetWind[0].avg = (datasetWind[0].sum / datasetWind[0].itemsLength).toFixed(1))
  const lastWindSpeed = newData[newData.length - 1].windSpeed
  if (lastWindSpeed !== '--' && lastWindSpeed) {
    datasetWind[2].y = lastWindSpeed
    datasetWind[2].text = lastWindSpeed + ' kph'
  }

  (datasetWind[1].itemsLength > 0) &&
    (datasetWind[1].avg = (datasetWind[1].sum / datasetWind[1].itemsLength).toFixed(1))
  const lastWindGusts = newData[newData.length - 1].gusts
  if (lastWindGusts !== '--' && lastWindGusts) {
    datasetWind[3].y = lastWindGusts
    datasetWind[3].text = lastWindGusts + ' kph'
  }

  (datasetHuPressure[0].itemsLength > 0) &&
    (datasetHuPressure[0].avg = (datasetHuPressure[0].sum / datasetHuPressure[0].itemsLength).toFixed(1));

  (datasetHuPressure[1].itemsLength > 0) &&
    (datasetHuPressure[1].avg = (datasetHuPressure[1].sum / datasetHuPressure[1].itemsLength).toFixed(1));

  (datasetRain[0].itemsLength > 0) &&
    (datasetRain[0].avg = (datasetRain[0].sum / datasetRain[0].itemsLength).toFixed(2));

  (datasetWindRoseDirection[0].itemsLength > 0) &&
    (datasetWindRoseDirection[0].avg = (datasetWindRoseDirection[0].sum / datasetWindRoseDirection[0].itemsLength).toFixed(0) + '°');

  (datasetLightIntensity[0].itemsLength > 0) &&
  (datasetLightIntensity[0].avg = Math.round((datasetLightIntensity[0].sum / datasetLightIntensity[0].itemsLength) * 10000) / 10000)
  const lastLightIntensity = newData[newData.length - 1].lightIntensity
  if (lastLightIntensity !== '--' && lastLightIntensity) {
    datasetLightIntensity[1].y = lastLightIntensity
    datasetLightIntensity[1].text = lastLightIntensity > 10000 ? `${(lastLightIntensity / 1000).toFixed()}k Lx` : lastLightIntensity + ' Lx'
  }

  result.rainIntensityTotal = parseFloat(result.rainIntensityTotal.toFixed(2))

  return result
}
