






























































import Vue from 'vue'
import Track from '@/components/data/common/Track/Track.vue'

import Plotly from 'plotly.js/lib/core'
import plotlyBar from 'plotly.js/lib/bar'
import plotlyPie from 'plotly.js/lib/pie'
import plotlyBarPolar from 'plotly.js/lib/barpolar'
import { DateTime } from 'luxon'

Plotly.register(plotlyBar)
Plotly.register(plotlyBarPolar)
Plotly.register(plotlyPie)

const defaultOptions = { alogo: false, displayModeBar: false }

export default Vue.extend({
  components: {
    'draw-track': Track
  },
  props: {
    id: String,
    plotlyDataset: {
      default: () => ([])
    },
    fontSize: {
      type: String,
      default: 'base'
    },
    displayMap: {
      type: Boolean,
      default: false
    },
    displayMin: {
      type: Boolean,
      default: true
    },
    displayMax: {
      type: Boolean,
      default: true
    },
    displayAvg: {
      type: Boolean,
      default: true
    },
    title: String,
    displayTitle: {
      type: Boolean,
      default: true
    },
    rangeMin: Number,
    rangeMax: [Number, Function],
    range2Min: Number,
    range2Max: Number,
    rangeMode: {
      type: String,
      default: 'normal'
    },
    range2Mode: {
      type: String,
      default: 'normal'
    },
    twoYAxes: Boolean,
    showLegend: Boolean,
    displayWind: {
      type: Boolean,
      default: false
    },
    windDataset: {
      default: () => ({ x: [], y: [] })
    },
    sessions: {
      default: () => ([])
    },
    displaySession: {
      type: Boolean,
      default: true
    },
    selectedRangeLocal: {
      default: () => ([])
    },
    centerMap: {
      default: () => ({})
    },
    eventGeojson: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    windToDisplay: [],
    traceHidden: []
  }),
  computed: {
    plotlyAnnotations () {
      if (!this.plotlyDataset) return []
      return this.plotlyDataset.filter(d => d.isAnnotation)
    },
    plotlyDataSeries () {
      if (!this.plotlyDataset) return []
      return this.plotlyDataset.filter(d => !d.isAnnotation)
    },
    layout () {
      let fontSizePixel = 12
      if (this.fontSize === 'sm') {
        fontSizePixel = 10
      }
      if (this.fontSize === 'xs') {
        fontSizePixel = 8
      }
      return {
        plot_bgcolor: 'transparent',
        paper_bgcolor: 'transparent',
        showlegend: false, // Do not use plotly legend because dataset is continuously rendered (ws) and when user select another period
        margin: {
          l: 30,
          r: 40,
          t: 40,
          b: 20
        },
        xaxis: {
          color: 'grey',
          gridcolor: '#222',
          showgrid: false,
          // fixedrange: true,
          side: 'top'
        },
        yaxis: {
          color: 'grey',
          gridcolor: '#222',
          fixedrange: true,
          rangemode: this.rangeMode
        },
        annotations: [],
        font: {
          size: fontSizePixel
        }
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeListener)
  },
  mounted () {
    this.drawGraph()
    this.displayWind === true && this.updateWindToDisplay()
    window.addEventListener('resize', this.resizeListener)
  },
  methods: {
    formatDataFromLightIntensity (data: number) {
      if (data >= 0) {
        if (data > 10000) return `${(data / 1000).toFixed()}k`
        return data
      } else {
        return '--'
      }
    },
    toggleLegend (nameLegend: string) {
      const indexItemToToggle = this.traceHidden.findIndex(item => item === nameLegend)
      if (indexItemToToggle >= 0) {
        this.traceHidden.splice(indexItemToToggle, 1)
      } else {
        this.traceHidden.push(nameLegend)
      }
      this.drawGraph()
    },
    resizeListener () {
    // ignore resize events as long as an actualResizeHandler execution is in the queue
      if (!this.resizeTimeout) {
        this.resizeTimeout = setTimeout(() => {
          this.resizeTimeout = null
          this.drawGraph()
          this.displayWind === true && this.updateWindToDisplay()
        }, 200)
      }
    },
    drawGraph () {
      const {
        plotlyDataSeries,
        rangeMin, rangeMax,
        range2Min, range2Max, range2Mode,
        twoYAxes, showLegend, displaySession
      } = this
      let shapes = []
      let annotations = []
      let maxValue = null

      // Hide trace selected by user
      const plotlyDataSeriesWithHiddenTrace = plotlyDataSeries.reduce((acc, dataset) => {
        const datasetFound = this.traceHidden.find(trace => trace === dataset.name)
        if (!datasetFound) {
          acc.push(dataset)
        }
        return acc
      }, [])

      if (this.sessions.length > 0 && this.selectedRangeLocal.length > 0) {
        shapes = this.sessions.reduce((acc, session) => {
          const sessionStart = DateTime.fromFormat(session.start, 'yyyyMMddHHmmss')
          const sessionEnd = DateTime.fromFormat(session.end, 'yyyyMMddHHmmss')
          const rangeBegin = DateTime.fromFormat(this.selectedRangeLocal[0], 'yyyyMMddHHmmss')
          const rangeEnd = DateTime.fromFormat(this.selectedRangeLocal[1], 'yyyyMMddHHmmss')

          const maxX0 = Math.max(sessionStart.valueOf(), rangeBegin.valueOf())
          const minX1 = Math.min(sessionEnd.valueOf(), rangeEnd.valueOf())

          if (rangeMax instanceof Function) {
            plotlyDataSeriesWithHiddenTrace.forEach(({ max }) => {
              if (!maxValue || maxValue < max) maxValue = max
            })
          } else {
            maxValue = rangeMax
          }

          if (!(sessionEnd < rangeBegin || sessionStart > rangeEnd) && displaySession) {
            // Dates de sessions comprise dans la période
            // Date de début de sessions comprise dans la période mais pas la date de fin
            // Date de début de sessions antérieur à la période sélectionnée mais la date de fin est incluse
            // Cas zoom dans une session
            acc.push({
              type: 'rect',
              xref: 'x',
              yref: 'y',
              layer: 'below',
              text: session.label.split('-')[0].trim(),
              x0: DateTime.fromMillis(maxX0).toISO({ includeOffset: false, suppressMilliseconds: true }),
              y0: 0,
              x1: DateTime.fromMillis(minX1).toISO({ includeOffset: false, suppressMilliseconds: true }),
              y1: maxValue || 1, // Bases only on first scale
              line: {
                width: 0
              },
              fillcolor: 'rgba(143, 167, 171, 0.5)',
              min: rangeMin || 1 // Bases only on first scale
            })
          }
          return acc
        }, [])

        if (shapes.length > 0 && displaySession) {
          annotations = shapes.map(({ text, x0, x1, y1, min }) => {
            // Compute y position to display session label according to scale/range
            let textPosition = 0
            const delta = y1 - min
            if (delta > 30) {
              textPosition = y1 - 4
            } else if (delta > 10) {
              textPosition = y1 - 2
            } else if (delta > 5) {
              textPosition = y1 - 0.5
            } else {
              textPosition = y1 - 0.05
            }
            return {
              showarrow: false,
              text: text,
              x: DateTime.fromMillis((DateTime.fromISO(x0).valueOf() + DateTime.fromISO(x1).valueOf()) / 2).toISO({ includeOffset: false, suppressMilliseconds: true }),
              y: textPosition
            }
          })
        }
      }

      (rangeMin && rangeMax) && (this.layout.yaxis.range = [rangeMin, rangeMax])

      if (twoYAxes === true) {
        this.layout.yaxis2 = {
          color: 'grey',
          side: 'right',
          showgrid: false,
          fixedrange: true,
          overlaying: 'y',
          rangemode: range2Mode
        };
        (range2Min && range2Max) && (this.layout.yaxis2.range = [range2Min, range2Max])
      }
      this.layout.annotations = this.plotlyAnnotations;

      (showLegend === false) && (this.layout.showLegend = showLegend)

      if (plotlyDataSeriesWithHiddenTrace.every(({ type }) => type !== 'barpolar')) {
        this.layout.shapes = shapes
        this.layout.annotations = [...this.layout.annotations, ...annotations]
      }

      Plotly.newPlot(this.$refs.plotlyGraph, plotlyDataSeriesWithHiddenTrace, this.layout, defaultOptions)
    },
    updateWindToDisplay () {
      this.windToDisplay = []
      const windDatasetLength = this.windDataset.y.length
      if (!this.$refs['chart' + this.id] || windDatasetLength === 0) return
      const iconSize = 18
      const chartWidth = this.$refs['chart' + this.id].offsetWidth - 70 // 50px is the space lost with plotly
      const numberOfWindOccurences = Math.round(chartWidth / iconSize)
      const stepBetweenWindData = Math.round(windDatasetLength / (numberOfWindOccurences - 1))
      for (let i = 0; i < numberOfWindOccurences; i++) {
        this.windToDisplay.push(this.windDataset.y[
          (i * stepBetweenWindData >= windDatasetLength ? windDatasetLength - 1 : i * stepBetweenWindData)
        ])
      }
    }
  },
  watch: {
    plotlyDataset () {
      this.drawGraph()
      this.resizeListener()
    },
    displaySession () {
      this.drawGraph()
    },
    windDataset () {
      this.displayWind === true && this.updateWindToDisplay()
    },
    fontSize () {
      this.drawGraph()
    }
  }

})
